@import 'flex';
@import 'layout';
@import 'markup';
@import 'typography';

html {
  font-size: 20px;
  line-height: 1.25;
}

body {
  font-family: Times;
  font-weight: 300;
  padding: 0;
  margin: 0;
  width: 100%;
}

li {
  margin-bottom: .25rem;
  max-width: 35rem;
}

img {
  margin: 1rem 0;
  width: 100%;
  background-color: #340ff0;
}

.background-yellow {
  background-color: rgba(254,246,212,0.25);
}

.background-blue {
  background-color: rgba(240,248,255,0.25);
}

nav ul {
  margin-left: 0;
  padding-left: 1rem;
}
nav li {
  display: inline-block;
  margin-right: 1rem;
}
