.header-content, footer, nav, main {
  margin: 0 auto;
  max-width: 50rem;
}

main {
  padding-top: 2rem;
}

header {
  width: 100%;
  background-color: #fff5d1;
  padding-top: .75rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  box-sizing: border-box;
}

.header-content {
  //padding: 0 2rem;
}

footer {
  font-size: .85rem;
}

article {
  padding: 0 1rem;
}

footer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  p {
    margin-left: 1rem;
  }
}

article p {
  max-width: 35rem;
}
