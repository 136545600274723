.flex-group {

  display: block;

  > section, article, div, h1, p {
    flex: none;
  }

  &.flex-start {
    justify-content: flex-start;

    > section, article, div, h1, p {
      flex: none;
      max-width: 100%;
      margin-right: .75rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.flex-end {
    justify-content: flex-end;

    > section, article, div, h1, p {
      flex: none !important;
      margin-right: .75rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.space-between {
    justify-content: space-between;

    > section, article, div, h1, p {
      flex: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.flex-center {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div, > button, > a {
    flex: none;
    margin-right: .75rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.flex-align-center {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 570px) {

  .flex-group {

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &.strip-margins-right {
      > div, > button, > a, > dl, > p {
        margin-right: 0;
      }
    }

    &.gutter-large {
      > * {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    > * {
      flex: 1;
      margin-right: .75rem;

      &:last-child {
        margin-right: 0;
      }

      &.flex-2 {
        flex: 2;
      }

      &.flex-3 {
        flex: 3;
      }

      &.flex-4 {
        flex: 4;
      }

      &.flex-5 {
        flex: 5;
      }
    }

    &.flex-end {
      > * {
        flex: none;
      }
    }
  }
}
