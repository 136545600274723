h1, h2, h3 {
  font-family: Times;
  font-weight: 400;
}

h1 {
  font-size: 2rem;
  margin:0;
  padding:0;
}

h1 a {
  text-decoration: none;
  letter-spacing: -.05rem;
}

h2 {
  font-size: 1.5rem;
  margin:0;
  padding:1.5rem 0 0;
  &.feature {
    display: inline;
    padding: 0;
    margin-left: -1rem;
    padding-left: 1rem;

    &.blue {
      background-color: #f0f8ff;
    }
    &.green {
      background-color: #daf7ea;
    }
    &.pink {
      background-color: #f5daf7;
    }
    &.yellow {
      background-color: #fff5d1;
    }
    &.boxed {
    }
  }
}

h3 {
  font-size: 1.25rem;
  margin:0;
  padding:2rem 0 .25rem 0;
}

p, li {
  font-size: 1rem;
  margin:0;
  padding:0;
}

p+p {
  margin-top: 1rem;
}
