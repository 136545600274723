
.title-case {
  text-transform: capitalize;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-1 {
  padding: 1rem;
}

.strip-padding-top {
  padding-top: 0;
}
.padding-left-1 {
  padding-left: 1rem;
}
